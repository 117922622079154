define('letstalkfootball/models/event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    date: attr('date'),
    documentLink: attr('string'),

    tasks: hasMany(),

    prettyDate: computed('date', function () {
      return moment(this.get('date')).format('DD/MM/YYYY');
    })
  });
});