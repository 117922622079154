define('letstalkfootball/routes/2017/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var id = _ref.id;

      if (id === 'new') {
        return this.store.createRecord('2017', {});
      }

      return this.store.findRecord('2017', id);
    },


    actions: {
      willTransition: function willTransition(transition) {
        this.get('controller').willTransition(transition);
      }
    }
  });
});