define('letstalkfootball/components/upload-area', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var inject = Ember.inject;
  exports.default = Ember.Component.extend({

    progress: 0,
    showProgress: false,

    firebaseApp: inject.service(),
    notifications: inject.service('notification-messages'),

    actions: {
      uploadDocument: function uploadDocument(_ref) {
        var _this = this;

        var _ref2 = _slicedToArray(_ref, 1),
            file = _ref2[0];

        var firebase = this.get('firebaseApp');
        var storageRef = firebase.storage().ref();
        var fileParts = file.name.split('.');
        var extension = fileParts[fileParts.length - 1] || 'pdf';
        var documentRef = storageRef.child('documents/' + this.get('path') + ('.' + extension));
        var uploadTask = documentRef.put(file);
        uploadTask.on('state_changed', function (snapshot) {
          var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
          _this.set('progress', progress / 100);
          _this.set('showProgress', true);
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
          }
        }, function (error) {
          _this.get('notifications').error('There was an error uploading file: ' + error.code);
        }, function () {
          var downloadURL = uploadTask.snapshot.downloadURL;

          _this.get('afterUpload')(downloadURL);
          _this.set('showProgress', false);
          _this.get('notifications').success('File uploaded successfully', { autoClear: true, clearDuration: 2000 });
        });
      }
    }
  });
});