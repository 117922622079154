define('letstalkfootball/models/task', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({

    isTask: true,

    taskId: attr('number'),
    date: attr('date'),
    day: attr('string'),
    time: attr('string'),
    procedure: attr('string'),
    action: attr('string'),
    responsible: attr('string'),
    documentLink: attr('string'),

    fileName: attr('string'),

    isAlert: attr('boolean'),
    isCompleted: attr('boolean'),
    completedBy: attr('string'),
    completedDate: attr('date'),

    event: belongsTo(),

    prettyDate: computed('date', function () {
      return moment(this.get('date')).format('DD/MM/YYYY');
    }),

    prettyDateWithDay: computed('date', 'day', function () {
      if (this.get('day')) {
        return this.get('prettyDate') + (' (' + this.get('day') + ')');
      }

      return this.get('prettyDate');
    })
  });
});