define('letstalkfootball/components/modal-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'div',
    classNames: ['modal', 'fade'],

    title: 'Default title',
    cancelButtonLabel: 'Cancel',
    primerButtonLabel: 'Save',

    actions: {
      primerButtonClicked: function primerButtonClicked() {
        this.sendAction();
      }
    }
  });
});