define('letstalkfootball/components/main-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      signOut: function signOut() {
        this.sendAction('signOut');
      }
    }
  });
});