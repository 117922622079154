define('letstalkfootball/controllers/2017/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    columns: computed(function () {
      return [{
        label: 'Episode No#',
        valuePath: 'episode',
        width: '50px'
      }, {
        label: 'Title',
        valuePath: 'title',
        width: '150px'
      }, {
        label: 'Date',
        valuePath: 'prettyDate',
        width: '150px'
      }, {
        label: 'Studio',
        valuePath: 'studio'
      }, {
        label: 'URL',
        valuePath: 'link'
      }];
    }),

    actions: {
      transitionToDetail: function transitionToDetail(row) {
        this.transitionToRoute('2017.show', row.get('content'));
      }
    }
  });
});