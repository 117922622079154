define('letstalkfootball/components/phone-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({

    dasherizeDigits: Ember.observer('value', function () {
      var valueString = this.get('value').replace(/\D+/g, '');

      this.set('value', valueString);
    })

  });
});