define('letstalkfootball/controllers/events/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    columns: computed(function () {
      return [{
        label: 'Name',
        valuePath: 'name',
        cellComponent: 'main-cell'
      }, {
        label: 'Date',
        valuePath: 'prettyDate'
      }, {
        label: 'Tasks',
        valuePath: 'tasks.length'
      }, {
        label: 'Document',
        valuePath: 'documentLink',
        cellComponent: 'task-options'
      }];
    }),

    actions: {
      transitionToEventsShow: function transitionToEventsShow(row) {
        var event = row.get('content');
        this.transitionToRoute('events.show', event);
      }
    }
  });
});