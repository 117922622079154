define('letstalkfootball/controllers/2016/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Controller.extend({

    notifications: inject.service('notification-messages'),

    willTransition: function willTransition(transition) {
      var model = this.get('model');

      if (model.get('hasDirtyAttributes')) {
        // TODO: Put remodal here for prettier modal
        if (confirm("Podcast has unsaved changes. Leaving the page will discard them. Continue?")) {
          model.rollbackAttributes();
        } else {
          transition.abort();
        }
      }
    },


    actions: {
      deleteItem: function deleteItem() {
        this.$('.modal').modal();
      },
      deleteConfirmed: function deleteConfirmed() {
        var item = this.get('item');
        item.destroyRecord();
      },
      deletePodcast: function deletePodcast(model) {
        var _this = this;

        if (confirm('Delete Podcast: ' + model.get('name'))) {
          model.destroyRecord().then(function () {
            return _this.get('model');
          }).then(function () {
            //TODO: add notification
            _this.get('notifications').success('Deleted successfully!', { autoClear: true, clearDuration: 500 });
            _this.transitionToRoute('2016');
          }, function (error) {
            _this.get('notifications').error('There was an error deleting the task ' + JSON.stringify(error));
          });
        }
      },
      savePodcast: function savePodcast() {
        var _this2 = this;

        this.get('model').save().then(function () {
          _this2.get('notifications').success('Saved successfully!', { autoClear: true, clearDuration: 500 });
          _this2.transitionToRoute('2016');
        }, function (error) {
          _this2.get('notifications').error('There was an error saving the event ' + JSON.stringify(error));
        });
      }
    }
  });
});