define('letstalkfootball/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    moment: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this.get('session').fetch().catch(function () {});
      this.get('moment').setLocale('en');
      this.get('moment').setTimeZone('Australia/Perth');
    },


    actions: {
      signIn: function signIn(email, password) {
        this.get('session').open('firebase', { provider: 'password', email: email, password: password }).then(function (data) {
          return console.log(data.currentUser);
        }); //eslint-disable-line no-console
      },
      signOut: function signOut() {
        this.get('session').close();
      }
    }
  });
});