define('letstalkfootball/models/podcast', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    var computed = Ember.computed;
    exports.default = _emberData.default.Model.extend({
        title: attr('string'),
        date: attr('date'),
        runsheet: attr('string'),
        studio: attr('string'),
        thanks: attr('string'),
        link: attr('string'),
        episode: attr('number'),
        linkUpload: attr('string'),

        prettyDate: computed('date', function () {
            return moment(this.get('date')).format('DD MMMM YYYY');
        })
    });
});