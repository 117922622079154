define('letstalkfootball/controllers/events/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({

    showEditTaskForm: false,
    editedTask: {},

    notifications: inject.service('notification-messages'),

    taskColumns: computed(function () {
      return [{
        label: 'Date',
        valuePath: 'prettyDateWithDay'
      }, {
        label: 'Action',
        valuePath: 'action'
      }, {
        label: 'Procedure',
        valuePath: 'procedure'
      }, {
        label: 'Time',
        valuePath: 'time'
      }, {
        label: 'Responsible',
        valuePath: 'responsible'
      }, {
        label: 'Options',
        valuePath: 'documentLink',
        cellComponent: 'task-options'
      }];
    }),

    tasks: computed('model.tasks.[]', function () {
      return this.get('model.tasks').toArray();
    }),

    willTransition: function willTransition(transition) {
      var model = this.get('model');

      if (model.get('hasDirtyAttributes')) {
        // TODO: Put remodal here for prettier modal
        if (confirm("Event has unsaved changes. Leaving the page will discard them. Continue?")) {
          model.rollbackAttributes();
        } else {
          transition.abort();
        }
      }

      this.set('editedTask', {});
    },


    actions: {
      saveEvent: function saveEvent() {
        var _this = this;

        this.get('model').save().then(function () {
          _this.get('notifications').success('Saved successfully!', { autoClear: true, clearDuration: 500 });
        }, function (error) {
          _this.get('notifications').error('There was an error saving the event ' + JSON.stringify(error));
        });
      },
      toggleShowEditTaskForm: function toggleShowEditTaskForm() {
        this.toggleProperty('showEditTaskForm');
      },
      saveTask: function saveTask(task) {
        var _this2 = this;

        var event = this.get('model');
        var record = task;

        if (!task.id) {
          // this is a new Task
          record = this.store.createRecord('task', task);
          record.set('event', event);
        }

        record.save().then(function () {
          return event.save();
        }).then(function () {
          _this2.get('notifications').success('Saved successfully!', { autoClear: true, clearDuration: 500 });
          _this2.set('editedTask', {});
          _this2.set('showEditTaskForm', false);
        }, function (error) {
          _this2.get('notifications').error('There was an error saving the task ' + JSON.stringify(error));
        });
      },
      addTask: function addTask() {
        this.set('editedTask', {});
        this.set('showEditTaskForm', true);
      },
      editTask: function editTask(content) {
        this.set('editedTask', content);
        this.set('showEditTaskForm', true);
      },
      deleteTask: function deleteTask(task) {
        var _this3 = this;

        if (confirm('Delete task ' + task.get('action'))) {
          //TODO put remodal for prettier confirm dialog here
          task.destroyRecord().then(function () {
            return _this3.get('model');
          }).then(function () {
            //TODO: add notification
            _this3.get('notifications').success('Deleted successfully!', { autoClear: true, clearDuration: 500 });
            _this3.set('editedTask', {});
            _this3.set('showEditTaskForm', false);
          }, function (error) {
            _this3.get('notifications').error('There was an error deleting the task ' + JSON.stringify(error));
          });
        }
      },
      setDocumentLink: function setDocumentLink(model, url, fileName) {
        if (model.set) {
          model.set('fileName', fileName);
          model.set('documentLink', url);
          model.save();
        } else {
          model.documentLink = url;
          model.fileName = fileName;
        }
      }
    }
  });
});