define('letstalkfootball/components/task-row', ['exports', 'ember-light-table/components/lt-row'], function (exports, _ltRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      htmlSafe = Ember.String.htmlSafe;
  exports.default = _ltRow.default.extend({
    classNameBindings: ['row.content.isCompleted:completed']
  });
});